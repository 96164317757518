.raw-input {
    margin: 0;
    outline: none;
    border: 0;
    background-color: transparent;
    padding: var(--spacing-small) 0;
    width: 100%;
    line-height: inherit;
    color: inherit;
    font-family: inherit;
    font-size: inherit;
}
