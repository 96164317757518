.popup {
    position: fixed;
    border-radius: var(--radius-popup-border);
    background-color: var(--color-foreground);
    filter: drop-shadow(0 0 var(--radius-blur-shadow) var(--color-shadow));
    max-width: calc(100vw - 2 * var(--spacing-large));

    --height-tip: 10px;
    --width-tip: 20px;

    .tip {
        position: absolute;
        top: unset;
        right: unset;
        bottom: unset;
        left: unset;
        background-color: #fff;
        width: var(--width-tip);
        height: var(--height-tip);
    }

    &.top {
        .tip {
            bottom: calc(-1 * var(--height-tip));
            clip-path: polygon(100% 0%, 0 0, 50% 100%);
        }
    }

    &.bottom {
        .tip {
            top: calc(-1 * var(--height-tip));
            clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
        }
    }

    &.left {
        .tip {
            right: var(--width-tip);
        }
    }

    &.right {
        .tip {
            left: var(--width-tip);
        }
    }

    .content {
        width: 100%;
        height: 100%;
        overflow: auto;
    }
}
