.option-container {
    display: flex;
    align-items: center;
    padding: var(--spacing-medium);
    overflow: auto;

    .option-item {
        display: flex;
        flex-grow: 1;
        gap: var(--spacing-medium);

        .checkmark {
            display: flex;
            align-items: center;
            flex-shrink: 0;
            opacity: 0;
            font-size: 1.2em;
        }

        .name {
            display: flex;
            align-items: center;
            flex-grow: 1;
            gap: var(--spacing-small);

            .icon {
                flex-shrink: 0;
                font-size: 1.2em;
            }

            .label {
                word-break: break-word;
            }
        }

        .meta {
            flex-shrink: 0;
            color: var(--color-text-watermark);
        }
    }

    &.active {
        color: var(--color-accent);

        .checkmark {
            opacity: 1;
        }
    }
}
