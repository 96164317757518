.navbar {
    display: flex;
    align-items: center;
    flex-direction: column;
    border-bottom: var(--width-separator-thin) solid var(--color-separator);
    background-color: var(--color-foreground);

    .container {
        display: flex;
        position: relative;
        align-items: center;
        flex-wrap: wrap;
        padding: var(--spacing-medium) var(--spacing-large);
        width: 100%;
        max-width: var(--width-navbar-content-max);
        color: var(--color-text);
        gap: var(--spacing-large);

        .app-brand {
            display: flex;
            align-items: center;
            flex-shrink: 0;
            gap: var(--spacing-small);

            .logo {
                height: 2rem;
            }
        }

        .nav-links {
            display: flex;
            flex-grow: 1;
            justify-content: flex-start;
            font-size: var(--font-size-large);
            gap: var(--spacing-small);

            .link {
                padding: var(--spacing-small);

                &:hover {
                    background-color: transparent;
                }
            }
        }

        .filter {
            display: flex;
            align-self: flex-end;
            flex-grow: 0.5;
            gap: var(--spacing-small);

            .label {
                color: var(--color-primary);
            }
        }
    }
}
