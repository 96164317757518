.button {
    display: flex;
    align-items: center;
    border: var(--width-separator-thin) solid rgba(0, 0, 0, 0.3);
    border-radius: var(--radius-button-border);
    padding: var(--spacing-small) var(--spacing-medium);
    color: rgba(0, 0, 0, 0.8);
    gap: var(--spacing-small);

    .icons,
    .actions {
        display: flex;
        font-size: 1.2em;
    }

    .children {
        display: flex;
    }

    &.primary {
        border-color: var(--primary-color);
        color: var(--primary-color);
    }
    &.secondary {
        border-color: var(--primary-accent);
        color: var(--primary-accent);
    }

    &.transparent {
        border: none;
        padding: 0;
        font-size: var(--font-size-medium);
    }

    &.action {
        border: none;
        padding: 0;
        font-size: var(--font-size-medium);
    }

    &.disabled {
        opacity: 0.5;
    }
}
